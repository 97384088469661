import { styled } from '@mui/system'

const Root = styled('div')`
	width: 100%;
	scrollbar-color: auto;

	#foursixty-container {
		max-width: 1100px;
		margin: auto;
	}
`

export { Root }
